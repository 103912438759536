@import './config/variables';

//$calendar-border: $tableLineColor;
//$cell-border: $tableLineColor;
//$rbc-calendar: $contentBackgroundColor;
//$event-bg: $buttonTheme;
//$event-border: darken($tableLineColor, 10%);
//$event-outline: $tableLineColor;
//$btn-bg: lighten($theme, 15%);

@import 'react-big-calendar/lib/sass/styles';

.rbc-calendar {
 color: $primaryTextColor !important;
 background-color: $contentBackgroundColor !important;
}

.rbc-toolbar button {
  color: $primaryTextColor !important;
  border-color: $tableLineColor !important;
  background-color: $contentBackgroundColor !important;
}

.rbc-time-slot {
  border-top: 0 !important;
  background-color: $contentBackgroundColor !important;
}

.rbc-today {
  background-color: $theme !important;
}

.rbc-off-range-bg {
  background-color: $contentBackgroundColor !important;
}

.rbc-toolbar .rbc-active {
  background-color: lighten($contentBackgroundColor, 15%);
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative;
  background-color: #FFFFFF;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

textArea {
  font-family: Poppins;
}

textarea::placeholder {
  color: #0bf;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

label {
  color: #ffffff;
}

input:focus {
  outline: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $secondaryTextColor;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $secondaryTextColor;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $secondaryTextColor;
}

textarea {
  resize: none;
}

textArea:focus {
  outline: none !important;
}

textarea::-webkit-input-placeholder {
  color: white;
  border-width: 0;
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: white;
  border-width: 0;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: white;
  border-width: 0;
}

textarea:-ms-input-placeholder {
  color: white;
  border-width: 0;
}

textarea::placeholder {
  color: white;
  border-width: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  color: $primaryTextColor;
}

